import React from "react";

const sizeGuide = [
  ["sock", "shoe", "age"],
  ["000", "15-17", "0M-6M"],
  ["00", "18-19", "6M-1Y"],
  ["1", "20-22", "1Y"],
  ["2", "23-25", "2Y-3Y"],
  ["4", "26-28", "4Y-5Y"],
  ["6", "29-31", "6Y-7Y"],
  ["8", "32-34", "8Y-9Y"],
  ["10", "35-37", "10Y-11Y"],
];

const SizeGuide = () => {
  const [headers, ...rows] = sizeGuide;
  return (
    <table className="text-blue-900 text-center">
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th key={i} className="px-8 py-1 border border-gray-500">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="border border-gray-500">
        {rows.map((row, i) => (
          <tr key={i} className="divide-x divide-gray-500">
            {row.map((column, i) => (
              <td key={i} className="py-0.5">
                {column}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SizeGuide;
