import React from "react";

import ShopLayout from "../components/new-layout";
import Breadcrumbs from "../components/breadcrumbs";
import SizeGuide from "../components/size-guide";

const SizeGuid = () => {
  let links = [
    ["/platform", "Home"],
    ["/contact", "Size Guide"],
  ];

  return (
    <ShopLayout>
      <div className="border-b-2 border-dashed border-gray-300">
        <Breadcrumbs links={links} />
        <div className="container lg:pl-12 my-8">
          <div>
            <h1 className="font-display text-blue-900 text-4xl mb-6">
              Size Guide
            </h1>
          </div>
          <div className="text-lg">
            <SizeGuide />
          </div>
        </div>
      </div>
    </ShopLayout>
  );
};

export default SizeGuid;
